export const i18n = {
  en: {
    admin: {
      errors: {
        default: "Error: An error has ocurred",
        invalidPassword: "Invalid password or one-time password",
      },
    },
    resources: {
      Org: {
        name: 'Organization |||| Organizations',
        fields: {
          id: 'ID',
          subscriptionId: 'Subscription',
          publicName: "Public Name",
          wallet: "Wallet",
          logoUrl: "Logo URL",
          backgroundColor: "Background color",
          foregroundColor: "Foreground color",
          footerHtml: "Footer Html",
          createdAt: "Created at",
          fullName: "Admin's full name",
          adminFullName: "Admin's full name",
          emailAddress: "Admin's email address",
          adminEmail: "Admin's email address",
          vidconnectClientId: "Vidconnect client ID",
          vidconnectClientSecret: "Vidconnect client secret",
          vidconnectClientSecretUpdate: "Vidconnect client secret",
          multiwallet: 'Multiwallet',
          orgIdDuplicate: "Duplicate Organization's Resources",
          webCallbacksUrl: 'Web callbacks URL',
          wallets: {
            VIDWALLET: "Vidwallet",
            ALTME: "Altme"
          },
          idEq: 'By ID:',
          publicNameLike: "By Public Name:",
          nameLike: 'By Name:',
          didLike: 'By Did:',
          labelLike: 'By Label:',
          pointerLike: 'By Pointer:',
          filterLike: 'By Filter:',
          valueLike: 'By Value:',
          helperLike: 'By Helper:',
          archivedAtIsSet: 'Archived?',
        },
        loading: 'Loading...',
        archivedAtIsSetAll: 'All',
        archivedAtIsSetTrue: 'Only archived',
        archivedAtIsSetFalse: 'Only not archived',
        statistics: {
          year: 'Year',
          month: 'Month',
          months: {
            January: "January",
            February: "February",
            March: "March",
            April: "April",
            May: "May",
            June: "June",
            July: "July",
            August: "August",
            September: "September",
            October: "October",
            November: "November",
            December: "December"
          },
          historic: "Historic",
          onlyYear: "Only by year",
          download: "Download Statistics"
        }
      },
      Person: {
        name: 'Person |||| Persons',
        many: 'Persons',
        fields: {
          id: 'ID',
          orgId: 'Organization',
          emailAddress: 'Email',
          fullname: 'FullName',
          admin: 'is Admin?',
          lang: 'Language',
          langs: {
            EN: 'English',
            ES: 'Spanish',
            CA: 'Catalan',
            EU: 'Euskera',
            FR: 'French',
            IT: 'Italian',
            GL: 'Gallego',
            PT: 'Portuguese'
          },
          roles: {
            ADMIN: 'Admin',
            MANAGER: 'Manager',
            AUDITOR: 'Auditor',
            OPERATOR: 'Operator',
            AGENT: 'Agent',
            REVOKED: 'Revoked'
          },
          registrationDate: 'Registration Date',
          not_accepted_yet: "Not accepted. Click here.",
          isTermsAccepted: "Terms Accepted?",
          termsUrl: "Terms Acceptance",
          already_accepted: "Terms were accepted",
          isAdmin: "Is Admin",
          maybeRole: "Role",
          role: "Role",
          idEq: 'By ID:',
          orgIdEq: 'By Organization:',
        }
      },
      EmailAddress: {
        name: 'Email |||| Emails',
        fields: {
          address: 'Address',
          personId: 'Person',
          createdAt: 'Created at',
          role: 'Role',
          orgId: 'Organization',
          addressLike: 'By Address:',
          personIdEq: 'By Person:',
          orgIdEq: 'By Organization:',
        },
      },
      Session: {
        name: 'Session |||| Sessions',
        fields: {
          id: 'Key',
          personId: 'Person',
          orgId: 'Organization',
          idLike: 'By Key:',
          personIdEq: 'By Person:',
          orgIdEq: 'By Organization:',
        },
      },
      WerifyPoint: {
        name: 'Werify Point |||| Werify Points',
        many: 'Werify Points',
        fields: {
          id: 'Nº',
          orgId: 'Organization',
          personId: 'Person',
          ruleId: 'Rule',
          name: 'Name',
          fullUrl: 'URL',
          createdAt: 'Created at',
          ArchivedAt: 'Archived at',
          nameLike: 'By Name:',
        }
      },
      Attempt: {
        name: 'Attempt |||| Attempts',
        seeMany: 'Show Attempts',
        fields: {
          id: 'Nº',
          orgId: 'Organization',
          werifyPointId: 'Werify Point',
          ruleId: 'Rule',
          name: 'Name',
          did: 'DID',
          state: 'State',
          states: {
            PENDING: 'Pending',
            APPROVED: 'Approved',
            REJECTED: 'Rejected',
            FAILED: 'Failed',
          },
          stateNotes: 'State Notes',
          startedAt: 'Started at',
          finishedAt: 'Scanning',
          lastActiveAt: 'Last Active at',
          vidchainUrl: 'Vidchain Url',
          idEq: 'By Nº:',
          orgIdEq: 'By Organization:',
          werifyPointIdEq: 'By Werify Point:',
          stateEq: 'By State:'
        },
        showMany: 'Show Attempts',
        approvedAttempts: 'Approved Attempts',
        rejectedAttempts: 'Rejected Attempts',
        failedAttempts: 'Failed Attempts'
      },
      Rule: {
        name: 'Rule |||| Rules',
        many: 'Rules',
        create: 'Create Rule',
        fields: {
          id: 'Nº',
          orgId: 'Organization',
          name: 'Name',
          stateNotes: 'State Notes',
          singleUseCredentials: 'Single-Use Credentials',
          wholeCredential: 'Captures complete data',
          createdAt: 'Created at',
          archivedAt: 'Archived at',
          redirectUrl: 'Redirect URL (Optional)',
          archived: 'Archived?',
          nameLike: 'By Name:'
        }
      },
      WebCallback: {
        name: 'Web Callback |||| Web Callbacks',
        seeMany: 'Show Web Callbacks',
        fields: {
          id: 'Nº',
          orgId: 'Organization',
          resourceId: 'Attempt',
          state: 'State',
          states: {
            PENDING: 'Pending',
            DONE: 'Done',
            FAILED: 'Failed',
          },
          createdAt: 'Created at',
          nextAttemptOn: 'Next try on',
          attemptsCount: 'Tries',
          idEq: 'By Nº:',
          orgIdEq: 'By Organization:',
          resourceIdEq: 'By Attempt:',
          stateEq: 'By State:'
        },
      },
      OutgoingEmailMessage: {
        name: 'Email Message |||| Email Messages',
        fields: {
          id: 'Nº',
          orgId: 'Organization',
          personId: 'Person',
          emailAddressId: 'Email Address',
          sent: 'Sent?',
          sentAt: 'Sent at',
          kind: 'Kind',
          kinds: {
            WELCOME: 'Welcome Email',
            HELP: 'Help',
          },
          customMessage: 'Custom Message',
          extraData: 'Extra Data',
          idEq: 'By Nº:',
          personIdEq: 'By Person:',
          emailAddressIdEq: 'By Email Address:',
          sentAtIsSet: 'By Sent at',
        },
      },
      OneTimeLogin: {
        name: 'One Time Login |||| One Time Logins',
        fields: {
          id: 'Nª',
          orgId: 'Organization',
          personId: 'Person',
          url: 'URL',
          idEq: 'By Nª:',
          orgIdEq: 'By Organization:',
          personIdEq: 'By Person:',
        },
      },
      KnownDid: {
        name: 'Known Did |||| Known Dids',
        many: 'Known Dids',
        create: 'Create Did',
        fields: {
          id: 'Nª',
          orgId: 'Organization',
          did: 'Did',
          label: 'Label',
          didLike: 'By Did:',
          labelLike: 'By Label:',
        },
        downloadValues: "Download Known Dids",
      },
      KnownAttribute: {
        name: 'Known Attribute |||| Known Attributes',
        many: 'Known Attributes',
        create: 'Create Attribute',
        fields: {
          id: 'Nª',
          orgId: 'Organization',
          pointer: 'Pointer',
          label: 'Label',
          filter: 'Filter',
          filters: {
            StringEnds: "termina con", 
            StringContains: "contiene el texto", 
            StringStarts: "empieza con", 
            Exists: "existe",
            OlderThanYears: "edad mayor o igual a (en años)",
            YoungerThanYears: "edad menor a (en años)",
            InDidList: "es un DID conocido",
            DidIs: "es un DID igual a",
            StringMatches: "coincide con el patrón",
            ArrayContains: "es lista y contiene el valor",
            DateAfter: "es una fecha posterior a",
            DateBefore: "es una fecha anterior a",
            NumberGreaterThan: "es un número mayor a",
            NumberLesserThan: "es un número menor a",
          },
          credentialType: 'Credential Type',
          credentialTypeLike: 'By Credential Type:',
          labelLike: 'By Label:',
          pointerLike: 'By Pointer:',
          filterLike: 'By Filter:',
        },
        downloadValues: "Download Known Attributes",
      },
      KnownCredentialType: {
        name: 'Known Credential Type |||| Known Credential Types',
        many: 'Known Credential Types',
        create: 'Create Credential Type',
        fields: {
          id: 'Nª',
          orgId: 'Organization',
          value: 'Value',
          helper: 'Helper',
          valueLike: 'By Value:',
          helperLike: 'By Helper:',
        },
        downloadValues: "Download Known Credential Types",
      },
      DidList: {
        name: "Did List |||| Did Lists",
        many: "Did Lists",
        fields: {
          orgId: 'Organization',
          name: "Name",
          count: "Number of Dids",    
          knownDidIds: "Dids",
          id: "Did",
          nameLike: "By Name:"
        },
        errorUpdating: "Error updating the Did list.",
        add_to_list: "Add",
        delete_from_list: "Remove from list",
        delete: {
          title: "Remove from list",
          content: "Are you sure you want to remove this Did from list?"
        },
        downloadIds: "Download Ids",
        downloadValues: "Download Values",
        uploadFromFile: "Upload from file",
        selectManually: "Or select manually",
        createByIds: "Create by Ids",
        createByValues: "Create by values"
      },
      AttributeList: {
        name: "Attribute List |||| Attribute Lists",
        many: "Attribute Lists",
        fields: {
          orgId: 'Organization',
          label: "Label",
          pointer: "Pointer",
          count: "Number of Values",    
          knownAttributeIds: "Values",
          id: "Value",
          labelLike: "By Label:",
          pointerLike: "By Pointer:",
          filterLike: "By Filter:"
        },
        updateValues: "Add values",
        errorUpdating: "Error updating the Attribute list.",
        delete_from_list: "Remove from list",
        delete: {
          title: "Remove from list",
          content: "Are you sure you want to remove this value from this Attribute List?"
        },
      },
      TermsAcceptance: {
        name: 'Terms Acceptance |||| Terms Acceptances',
        fields: {
          id: 'ID',
          personId: 'Person',
          orgId: 'Organization',
          accepted: 'Accepted',
          hash: 'Hash',
          evidence: 'Evidence',
          idEq: 'By ID:',
          personIdEq: 'By Person:',
          orgIdEq: 'By Organization:',
          acceptedIsSet: 'Accepted?',
        },
        acceptedIsSetAll: 'All',
        acceptedIsSetTrue: 'Only accepted',
        acceptedIsSetFalse: 'Only not accepted',
      },
      OrgDeletion: {
        name: 'Organization Deletion |||| Organization Deletions',
        create: 'Request a Organization Deletion',
        edit: 'Cancel Organization Deletion',
        fields: {
          id: 'Nº',
          orgId: 'Organization',
          storyId: 'Story',
          reason: 'Reason',
          reasons: {
            USER_REQUEST: 'User Request',
            MANAGEMENT_DECISION: 'Management Decision',
            INACTIVITY: 'Inactivity',
          },
          description: 'Description',
          completed: 'Completed',
          approvingAdminUser: 'Approving Admin User',
          startedAt: 'Started At',
          idEq: 'By Nº:',
          orgIdEq: 'By Organization:',
          storyIdEq: 'By Story:',
          reasonEq: ' By Reason:',
          completedEq: 'By Completed:',
        },
      },
      AdminUser: {
        name: 'Admin User |||| Admin Users',
        create: 'Create an Admin User',
        fields: {
          id: 'ID',
          username: 'Username',
          password: 'Password',
          otpSeed: 'OTP Seed',
          otp: 'OTP',
          role: 'Role',
          createdAt: 'Created At',
          usernameLike: 'By Username:',
          roleEq: 'By Role:',
          roles: {
            admin: 'Admin',
            superadmin: 'SuperAdmin',
          },
        },
      },
      ChangePassword: {
        name: 'Change Password',
        password: 'Password',
        newPassword: 'New Password',
        reEnterNewPassword: 'Re-enter new password',
        otp: 'OTP',
        confirmationFailed: 'Passwords must match',
      },
      actions: {
        createTemplate: 'Create Template',
        requestOrgDeletion: 'Request Org Deletion',
        cancelOrgDelete: 'Cancel Deletion',
        orgDeletionMessage: 'Be careful, once you request to delete an organization. The organization will not be able to access their own data.',
        orgDeletionCancelMessage: 'You are about to cancel the request to delete the organization, making all their data available again.',
        physicalDeletionConfirm: 'Be careful, you are about to permanently delete all data for this organization.',
        physicalDeletionCompleted: 'Physical deletion completed.',
        physicalDelete: 'Physical Delete',
        updated: 'Element updated',
        created: 'Element created',
        changePassword: 'Change Password',
        loading: 'Loading...',
        copy_to_clipboard: 'Link copied to clipboard',
        click_here: 'Click here to visit',
        details: 'Details',
        errorUpdatingResource: "Error: invalid fields for %{resource}.",
        errorInvalidFieldsResource: "Error: invalid fields.",
        createOne: "Create one",
        createFromFile: "or create many from a file",
        upload: "Upload CSV",
      },
    },
    patch: {
      auth: {
        auth_check_error: "Por favor inicie sesión para continuar"
      },
      action: {
        unselect: "Deseleccionar"
      },
      navigation: {
        skip_nav: "Saltar al contenido",
        page_rows_per_page: "Filas por página:"
      }
    }
  }
};
