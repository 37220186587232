import {
  Datagrid, ShowButton, EditButton, TextField, TextInput, ReferenceField, useTranslate,
  required, Show, NumberField, Edit, SimpleForm, TopToolbar, Create, SimpleShowLayout,
  DeleteWithConfirmButton, useSafeSetState, useNotify, useDataProvider, useRedirect
} from 'react-admin';
import { Box, Container, Divider } from '@mui/material';
import {OnlySaveToolbar} from "../components/bottom_toolbars";
import FilterTextInput from "../components/filter_textinput";
import OrgTextField from '../components/org_textfield';
import SelectOrgs from '../components/select_orgs';
import CSVFileInputKnownResources from '../components/csv_file_input_known_resources';
import Loading from '../components/loading';
import { useWatch } from 'react-hook-form';
import _ from "lodash";


const knownDidFilters = [
  <FilterTextInput source="didLike" alwaysOn />,
  <FilterTextInput source="labelLike" alwaysOn />,
];

const knownDidGrid =
  <Datagrid bulkActionButtons={false}>
    <TextField source='id' />
    <TextField source='label' />
    <TextField source='did' />
    <ShowButton />
    <EditButton />
    <DeleteWithConfirmButton redirect={false} />
  </Datagrid>


function KnownDidShow(){

  return (
    <Show actions={<TopToolbar><EditButton /><DeleteWithConfirmButton redirect="/Org"/></TopToolbar>} >
      <SimpleShowLayout>
        <NumberField source='id' />
        <ReferenceField source="orgId" reference="Org" link="show">
          <OrgTextField source="id" />
        </ReferenceField>
        <TextField source='label' />
        <TextField source='did' />
      </SimpleShowLayout>
    </Show>
  );
}

const KnownDidCreate = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();

  const validateFields = fields => {
    return _.includes(fields, "did") && _.includes(fields, "label");
  }

  const createKnownDids = async ({results, orgId}) => {
    try {
      for (const row of results.data) {
        await dataProvider.create("KnownDid", {
          data: { orgId, did: row.did, label: row.label }
        });
      }
      redirect(`/Org/${orgId}/show/knownDid`);
    } catch (e) {
      notify(`Error: ${e}`, { type: 'error' });
    }
  }

  return (
    <GenericCreateForm
      createFunction={createKnownDids}
      validateFields={validateFields}
      resource="KnownDid"
      id="upload-known-dids"
    >
      <Box display="flex" flexDirection="row" justifyContent="space-evenly" margin="1.5em">
        <TextInput source="label" autoComplete="off" validate={required()} />
        <TextInput source="did" autoComplete="off" validate={required()} />
      </Box>
    </GenericCreateForm>
  );
}


const GenericCreateForm = ({ id, createFunction, validateFields, resource, children }) => {
  const [orgId, setOrgId] = useSafeSetState(null);
  const [loading, setLoading] = useSafeSetState(false);
  const translate = useTranslate();

  const WatchDids = () => {
    const watchOrgId = useWatch({ name: 'orgId' });
    if (orgId !== watchOrgId) {
      setOrgId(watchOrgId);
    }
    return null;
  };

  if (loading) return <Loading />;

  return (
    <Create title={`resources.${resource}.create`} resource={resource} redirect="show">
      <SimpleForm warnWhenUnsavedChanges>
        <Container>
          <SelectOrgs source="orgId" validate={required()} />
          <Divider>{translate("resources.actions.createOne")}</Divider>
          {children}
          <Divider>{translate("resources.actions.createFromFile")}</Divider>
          <Box display="flex" flexDirection="row" justifyContent="space-evenly" margin="1.5em">
            <CSVFileInputKnownResources
              id={id}
              resource={resource}
              setLoading={setLoading}
              buttonLabel="resources.actions.upload"
              handleCreateResources={createFunction}
              orgId={orgId}
              validateFields={validateFields} 
            />
          </Box>
          <WatchDids />
        </Container>
      </SimpleForm>
    </Create>
  );
};


const KnownDidEdit = () => {
  return (
    <Edit redirect="show">
      <SimpleForm toolbar={<OnlySaveToolbar />} warnWhenUnsavedChanges>
        <TextInput readOnly source="id" />
        <TextInput source="label" autoComplete="off" validate={required()} />
        <TextInput source="did" autoComplete="off" validate={required()} />
      </SimpleForm>
    </Edit>
  )
};

export {knownDidFilters, knownDidGrid, KnownDidShow, KnownDidCreate, KnownDidEdit, GenericCreateForm};
