import { Pagination } from 'react-admin'
import Countrily from "countrily";
import JSZip from "jszip";
import { saveAs } from "file-saver";
  
export const PaginationDefault = props => <Pagination rowsPerPageOptions={[20]} {...props} />;

export const defaultSort = { field: 'id', order: 'DESC' as 'ASC' | 'DESC' };

interface base64 {
  (file: any): Promise<string>;
}

export const convertBase64: base64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      if (typeof fileReader.result === "string") {
        resolve(fileReader.result);
      };
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const demonymList = () => {
  var demonyms = [];
  Countrily.all().map(value => {
    if (!value.demonym) return false;
    if (demonyms.filter(f => f.name === value.demonym).length > 0) return false;
    demonyms.push({ name: value.demonym})
    return false;
  })
  return demonyms;
}

export const parseDate = (date) => {
  return new Date(date).toLocaleString([], {
    timeStyle: "short",
    dateStyle: "medium"
  });
}

export const createZipAndDownload = async (files, kycRequestId) => {
  var zip = new JSZip();
  for (let file of files) {
    const base64Response = await fetch(`data:${file.contentType};base64,${file.payload}`);
    let blob = await base64Response.blob();
    zip.file(file.filename, blob);
  }
  let zipName = "evidence_" + kycRequestId + ".zip";
  return zip.generateAsync({type:"blob"})
  .then(function (blob) {
      saveAs(blob, zipName);
  });
}

export function formatJsonInline(params){
  return <pre>
    { Object.entries(orderJsonObject(JSON.parse(params))).map(([key, value] : [string, string]) =>
    <span key={key} className="params">
        <span><b>{key}</b>: </span>
        <span>{value}</span>
        <br/>
    </span>
    ) }
  </pre>
}

const orderJsonObject = (unorderedObject) => {
  return Object.keys(unorderedObject).sort().reduce(
    (obj, key) => { 
      obj[key] = unorderedObject[key]; 
      return obj;
    }, 
    {}
  );
}

export const convertToCSV = (arr) => {
  const header = Object.keys(arr[0]).join(',');
  const values = arr.map(obj => Object.values(obj).join(',')).join('\n');
  return `${header}\n${values}`;
};

export const downloadCSV = (finalContent, name) => {
  // Convert data to CSV
  const csvContent = convertToCSV(finalContent);

  // Create a blob from the CSV content
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

  // Create an object URL and trigger a download
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', `${name}.csv`);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}