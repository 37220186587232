import React, {useEffect, useState} from "react";
import { Admin, Resource, CustomRoutes } from 'react-admin';
import authProvider from './components/auth_provider';
import buildGraphQLProvider, { buildQuery } from 'ra-data-graphql-simple';
import {AdminUserList, AdminUserCreate} from "./views/admin_user";
import {OrgList, OrgShow, OrgCreate, OrgEdit} from './views/org';
import {EmailList, EmailShow} from './views/email_address';
import {PersonList, PersonShow, PersonCreate, PersonEdit} from './views/person';
import {OrgDeletionList, OrgDeletionShow, OrgDeletionCreate}
        from './views/org_deletion';
import {OneTimeLoginCreate, OneTimeLoginList, OneTimeLoginShow} from './views/one_time_login';
import {TermsAcceptanceList, TermsAcceptanceShow}
        from './views/terms_acceptance';
import { WerifyPointShow } from "./views/werify_point";
import { AttemptList, AttemptShow } from "./views/attempt";
import { RuleCreate, RuleEdit, RuleShow } from "./views/rule";
import {
        PermIdentity, VpnKey, Create, TextSnippet, AccountCircle, DriveFileMove, Inventory, Inventory2Outlined,
        SupervisorAccount, AlternateEmail, CardMembership, Delete, Business, ConnectedTv, Outbox, Email
} from '@mui/icons-material';
import { Login } from 'ra-ui-materialui';
import { LoginForm } from './views/login';
import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  from,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import MyLayout from './components/my_layout'
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
import {i18n as adminMessages} from './i18n';
import { AdminUserChangePassword } from "./views/change_password";
import { Route } from "react-router-dom";
import werifyTheme from "./theme";
import gql from 'graphql-tag';
import { KnownDidCreate, KnownDidEdit, KnownDidShow } from "./views/known_did";
import { KnownAttributeCreate, KnownAttributeEdit, KnownAttributeShow } from "./views/known_attribute";
import { KnownCredentialTypeCreate, KnownCredentialTypeEdit, KnownCredentialTypeShow } from "./views/known_credential_type";
import { SessionList, SessionShow } from "./views/session";
import { DidListCreate, DidListList, DidListShow } from "./views/did_list";
import { WebCallbackList, WebCallbackShow } from "./views/web_callback";
import { AttributeListCreate, AttributeListList, AttributeListShow } from "./views/attribute_list";
import { OutgoingEmailMessageList, OutgoingEmailMessageShow } from "./views/outgoing_email_message";


const CustomLogin: React.FC = (props) => {
  return (
    <Login {...props}>
      <LoginForm />
    </Login>
  );
};


function App() {

  const api_url = `${process.env.REACT_APP_BACK_OFFICE_API || ''}/graphql/`
  const [dataProvider, setDataProvider] = useState(null);

  useEffect(() => {
    async function initApp() {
      const httpLink = new HttpLink({ uri: api_url });

      const authMiddleware = new ApolloLink((operation, forward) => {
        let authorization = authProvider.getToken() || null;
        operation.setContext(({ headers = {} }) => ({
          headers: {
          ...headers,
          "Authorization": authorization
          }
        }));
        
        return forward(operation);
      })

      const logoutLink = onError(({ networkError, graphQLErrors }) => {
        authProvider.checkError(networkError, graphQLErrors);
      })

      const client = new ApolloClient({
        link: from([
          authMiddleware,
          logoutLink,
          httpLink
        ]),
        cache: new InMemoryCache(),
      });
          
      const werifyAppBuildQuery = introspection => (fetchType, resource, params) => {
        if (resource === 'PhysicalDeletion') {

          const parser = function(data){
            if(data.data.data == null ){
              return { data: { done: true, id: true } };
            } else {
              return buildQuery(introspection)('GET_ONE', 'OrgDeletion', params).parseResponse(data);
            }
          }

          return {
            parseResponse: parser,
            variables: params.data,
            query: gql`mutation($org_deletion_id:Int!){
              data: physicalDeletion(orgDeletionId: $org_deletion_id) {
                id,
                completed,
              }
            }`
          };

        } else {
          return buildQuery(introspection)(fetchType, resource, params);
        }
      };

      buildGraphQLProvider({ client, buildQuery: werifyAppBuildQuery}).then(provider => { setDataProvider(() => provider) });
    }

    initApp();
  }, [api_url]);

  if (!dataProvider) {
    return <div> Loading </div>;
  }

  const messages = {
    en: { ...englishMessages, ...adminMessages.en },
  };
  const i18nProvider = polyglotI18nProvider(locale => messages[locale], "en");

  return (
    <Admin
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      loginPage={CustomLogin}
      layout={MyLayout}
      theme={werifyTheme}
      >
      {permissions => [
        <Resource
          name="Org"
          list={OrgList}
          show={OrgShow}
          create={OrgCreate}
          edit={OrgEdit}
          icon={Business}
        />,
        <Resource
          name="Person"
          list={PersonList}
          show={PersonShow}
          create={PersonCreate}
          edit={PersonEdit}
          icon={PermIdentity}
        />,
        <Resource
          name="TermsAcceptance"
          list={TermsAcceptanceList}
          show={TermsAcceptanceShow}
          icon={Create}
        />,
        <Resource
          name="EmailAddress"
          list={EmailList}
          show={EmailShow}
          icon={AlternateEmail}
        />,
        <Resource
          name="Attempt"
          list={AttemptList}
          show={AttemptShow}
          icon={DriveFileMove}
        />,
        <Resource
          name="WebCallback"
          list={WebCallbackList}
          show={WebCallbackShow}
          icon={Outbox}
        />,
        <Resource
          name="OutgoingEmailMessage"
          list={OutgoingEmailMessageList}
          show={OutgoingEmailMessageShow}
          icon={Email}
        />,
        <Resource
          name="WerifyPoint"
          show={WerifyPointShow}
        />,
        <Resource
          name="Rule"
          show={RuleShow}
          create={RuleCreate}
          edit={RuleEdit}
        />,
        <Resource
          name="KnownDid"
          show={KnownDidShow}
          create={KnownDidCreate}
          edit={KnownDidEdit}
          icon={TextSnippet}
        />,
        <Resource
          name="KnownCredentialType"
          show={KnownCredentialTypeShow}
          create={KnownCredentialTypeCreate}
          edit={KnownCredentialTypeEdit}
          icon={CardMembership}
        />,
        <Resource
          name="KnownAttribute"
          show={KnownAttributeShow}
          create={KnownAttributeCreate}
          edit={KnownAttributeEdit}
          icon={AccountCircle}
        />,
        <Resource
          name="DidList"
          list={DidListList}
          show={DidListShow}
          create={DidListCreate}
          icon={Inventory}
        />,

        <Resource
          name="AttributeList"
          list={AttributeListList}
          show={AttributeListShow}
          create={AttributeListCreate}
          icon={Inventory2Outlined}
        />,
        <Resource
          name="Session"
          list={SessionList}
          show={SessionShow}
          icon={ConnectedTv}
        />,
        <Resource
          name="OneTimeLogin"
          list={OneTimeLoginList}
          show={OneTimeLoginShow}
          create={OneTimeLoginCreate}
          icon={VpnKey}
        />,
        <Resource
          name="OrgDeletion"
          list={OrgDeletionList}
          show={OrgDeletionShow}
          create={OrgDeletionCreate}
          icon={Delete}
        />,
        <Resource
          name="Statistic"
        />,

        <CustomRoutes>
          <Route path="/ChangePassword" element={<AdminUserChangePassword />} />
        </CustomRoutes>,

        permissions === 'SuperAdmin'
          ? <Resource 
              name="AdminUser"
              list={AdminUserList}
              create={AdminUserCreate}
              icon={SupervisorAccount}
              options={{ label: 'Admin Users' }}
            />
          : null
      ]}
    </Admin>
  );
}

export default App;
