import { useEffect } from "react";
import {
  List, Datagrid, ShowButton, EditButton, TextField, TextInput, FunctionField,
  Show, NumberField, Edit, SimpleForm, TopToolbar, Tab, TabbedShowLayout, FilterForm,
  Button, ReferenceManyField, Create, required, email,
  useSafeSetState, BooleanField, BooleanInput, useRedirect, useTranslate, useNotify, useDataProvider, SimpleShowLayout
} from 'react-admin'
import { Button as MuiButton, Divider, Box } from '@mui/material';
import { PaginationDefault, defaultSort, downloadCSV } from "../components/utils";
import {OnlySaveToolbar} from "../components/bottom_toolbars";
import { useParams } from 'react-router-dom';
import {
  Delete, TextSnippet, DriveFileMove, DesignServices, AccountCircle, CardMembership, Download
} from '@mui/icons-material';
import FilterTextInput from "../components/filter_textinput";
import ParsedDateTextField from "../components/parsed_date_textfield";
import { personGrid } from "./person";
import { werifyPointFilters, werifyPointGrid } from "./werify_point";
import { ruleFilters, ruleGrid } from "./rule";
import { knownDidFilters, knownDidGrid } from "./known_did";
import { knownCredentialTypeFilters, knownCredentialTypeGrid } from "./known_credential_type";
import { knownAttributeFilters, knownAttributeGrid } from "./known_attribute";
import { TopToolbarWithCreateButton } from "../components/top_toolbars";
import SelectLang from "../components/select_lang";
import { didListDatagrid, didListFilters } from "./did_list";
import SelectWallet from "../components/select_wallet";
import TranslatedTextField from "../components/translated_textfield";
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import _ from "lodash";
import SelectStatisticFilter from "../components/select_statistics_filter";
import SelectOrgs from "../components/select_orgs";
import { attributeListDatagrid, attributeListFilters } from "./attribute_list";


function OrgList() {
  const userFilters = [
    <FilterTextInput source="publicNameLike" alwaysOn />,
    <FilterTextInput source="idEq" />,
  ];

  return (
    <List
      empty={false}
      sort={defaultSort}
      filters={userFilters}
      perPage={20}
      pagination={<PaginationDefault />}
      actions={<TopToolbarWithCreateButton/>}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source='id' />
        <TextField source='publicName' />
        <TranslatedTextField source="wallet" translation="resources.Org.fields.wallets" />
        <TextField source='clientId' sortable={false} />
        <TextField source='vidconnectClientId' sortable={false}/>
        <TextField source="adminEmail" sortable={false} />
        <ParsedDateTextField source="createdAt" />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  );
}


function OrgShow(){
  const { id } = useParams();
  const [werifyPointFilter, setWerifyPointFilter] = useSafeSetState({});
  const [ruleFilter, setRuleFilter] = useSafeSetState({});
  const [knownDidFilter, setKnownDidFilter] = useSafeSetState({});
  const [knownCredentialTypeFilter, setKnownCredentialTypeFilter] = useSafeSetState({});
  const [knownAttributeFilter, setKnownAttributeFilter] = useSafeSetState({});
  const [didListFilter, setDidListFilter] = useSafeSetState({});
  const [attributeListFilter, setAttributeListFilter] = useSafeSetState({});
  const [statistics, setStatistics] = useSafeSetState({});
  const redirect = useRedirect();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const location = useLocation();
  const translate = useTranslate();

  useEffect(() => {
    if (location.pathname.match(/\/show$/) || location.pathname.match(/\/show#$/)) {
      redirect(`${location.pathname}/details`);
    } 
  }, [location]);

  const ListActionsRedirectAutocomplete = () => {
    return (
      <TopToolbar>
        {id && 
          <>
            <Button 
              href={`#/Rule/create?source={"orgId":"${id}"}`}
              label="resources.Rule.create">
              <DesignServices/>
            </Button>
            <Button 
              href={`#/KnownDid/create?source={"orgId":"${id}"}`}
              label="resources.KnownDid.create">
              <TextSnippet/>
            </Button>
            <Button 
              href={`#/KnownCredentialType/create?source={"orgId":"${id}"}`}
              label="resources.KnownCredentialType.create">
              <CardMembership/>
            </Button>
            <Button 
              href={`#/KnownAttribute/create?source={"orgId":"${id}"}`}
              label="resources.KnownAttribute.create">
              <AccountCircle/>
            </Button>
            <EditButton />
          </>
        }
      </TopToolbar>
    )
  };

  const getResource = async (orgId, resource, perPage) => {
    return await dataProvider.getList(resource, {
      sort: {field: "id", order: "ASC"}, pagination: { page: 1, perPage }, filter: {orgIdEq: orgId}
    });
  }

  const handleDownloadValues = async (orgId, resource, fields) => {
    try {
      const {total} = await getResource(orgId, resource, 1);
      const {data} = await getResource(orgId, resource, total);
      const finalDids = _.map(data, a => _.pick(a, fields));
      downloadCSV(finalDids, `${resource}_values`);
    } catch (e) {
      notify('admin.errors.default', {type: 'warning'});
    }
  };  

  return (
    <Show actions={<ListActionsRedirectAutocomplete />} >
      <TabbedShowLayout syncWithLocation={true}>
        <Tab label="resources.actions.details" path="details">
          <NumberField source='id' />
          <TextField source="adminFullName" />
          <TextField source='clientId' /> 
          <TextField source='publicName' />
          <TranslatedTextField source="wallet" translation="resources.Org.fields.wallets" />
          <TextField source="adminEmail" />
          <TextField source='logoUrl' />
          <TextField source="backgroundColor" />
          <TextField source="foregroundColor" />
          <TextField source="footerHtml" />
          <TextField source='webCallbacksUrl' />
          <TextField source='vidconnectClientId' />
          <TextField source='vidconnectClientSecret' />
          <BooleanField source='multiwallet' />
          <ParsedDateTextField source="createdAt" />
          <FunctionField render={ record => {
            return <Button
              href={`#/Attempt?displayedFilters={"orgIdEq":true}&filter={"orgIdEq":${id}}`}
              label="resources.Attempt.seeMany"
            >
            <DriveFileMove />
          </Button>
          }} />

          <FunctionFieldForDownload
            id="download-known-dids"
            handleDownloadValues={handleDownloadValues}
            resource="KnownDid"
            fields={['label', 'did']}
          />
          <FunctionFieldForDownload
            id="download-known-credential-types"
            handleDownloadValues={handleDownloadValues}
            resource="KnownCredentialType"
            fields={['value', 'helper', 'enabledInVidconnect']}
          />
          <FunctionFieldForDownload
            id="download-known-attributes"
            handleDownloadValues={handleDownloadValues}
            resource="KnownAttribute"
            fields={['label', 'pointer', 'filter', 'credentialType']}
          />

          <FunctionField
            render={() => { 
              if (!id) return;
              return <Button 
                href={`#/OrgDeletion/create?source={"orgId":"${id}"}`}
                label="resources.actions.requestOrgDeletion">
                <Delete/>
              </Button>;
            }}
          />
          <Box sx={{backgroundColor: "#fff", border: 0}}>
            {statistics && (
              <>
                <SelectStatisticFilter id={id} setStatistics={setStatistics} />
                <Divider />
                <SimpleShowLayout>
                  <NumberField source="persons" record={statistics} label={translate("resources.Person.name", {smart_count: 2})} />
                  <NumberField source="werifyPoints" record={statistics} label={translate("resources.WerifyPoint.name", {smart_count: 2})} />
                  <NumberField source="rules" record={statistics} label={translate("resources.Rule.name", {smart_count: 2})} />
                  <NumberField source="approvedAttempts" record={statistics} label={translate("resources.Attempt.approvedAttempts")} />
                  <NumberField source="rejectedAttempts" record={statistics} label={translate("resources.Attempt.rejectedAttempts")} />
                  <NumberField source="failedAttempts" record={statistics} label={translate("resources.Attempt.failedAttempts")} />
                </SimpleShowLayout>
              </>
            )}
          </Box> 
        </Tab>
        <Tab label="resources.Person.many" path="persons">
          <div className="nested-resource" >
            <ReferenceManyField reference="Person" target="orgIdEq" label=""
              perPage={20}
              pagination={<PaginationDefault />}
              filter={{archivedAtIsSet: false}}
            >
              {personGrid}
            </ReferenceManyField>
          </div>
        </Tab>
        <Tab label="resources.WerifyPoint.many" path="werifyPoint">
          <div className="nested-resource" >
            <FilterForm
              filters={werifyPointFilters}
              setFilters={setWerifyPointFilter}
             />
            <ReferenceManyField reference="WerifyPoint" target="orgIdEq" label=""
              sort={defaultSort}
              filter={{ ...werifyPointFilter, archivedAtIsSet: false }}
              perPage={20}
              pagination={<PaginationDefault />}
            >
              {werifyPointGrid}
            </ReferenceManyField>
          </div>
        </Tab>
        <Tab label="resources.Rule.many" path="rule">
          <div className="nested-resource" >
            <FilterForm
              filters={ruleFilters}
              setFilters={setRuleFilter}
              defaultValues={{archivedAtIsSet: false}}
             />
            <ReferenceManyField reference="Rule" target="orgIdEq" label=""
              sort={defaultSort}
              filter={{ ...ruleFilter, archivedAtIsSet: false }}
              perPage={20}
              pagination={<PaginationDefault />}
            >
              {ruleGrid}
            </ReferenceManyField>
          </div>
        </Tab>
        <Tab label="resources.KnownDid.many" path="knownDid">
          <div className="nested-resource" >
            <FilterForm
              filters={knownDidFilters}
              setFilters={setKnownDidFilter}
             />
            <ReferenceManyField reference="KnownDid" target="orgIdEq" label=""
              sort={defaultSort}
              filter={knownDidFilter}
              perPage={20}
              pagination={<PaginationDefault />}
            >
              {knownDidGrid}
            </ReferenceManyField>
          </div>
        </Tab>
        <Tab label="resources.KnownCredentialType.many" path="knownCredentialType">
          <div className="nested-resource" >
            <FilterForm
              filters={knownCredentialTypeFilters}
              setFilters={setKnownCredentialTypeFilter}
             />
            <ReferenceManyField reference="KnownCredentialType" target="orgIdEq" label=""
              sort={defaultSort}
              filter={knownCredentialTypeFilter}
              perPage={20}
              pagination={<PaginationDefault />}
            >
              {knownCredentialTypeGrid}
            </ReferenceManyField>
          </div>
        </Tab>
        <Tab label="resources.KnownAttribute.many" path="knownAttribute">
          <div className="nested-resource" >
            <FilterForm
              filters={knownAttributeFilters}
              setFilters={setKnownAttributeFilter}
             />
            <ReferenceManyField reference="KnownAttribute" target="orgIdEq" label=""
              sort={defaultSort}
              filter={knownAttributeFilter}
              perPage={20}
              pagination={<PaginationDefault />}
            >
              {knownAttributeGrid}
            </ReferenceManyField>
          </div>
        </Tab>
        <Tab label="resources.DidList.many" path="DidList">
          <div className="nested-resource" >
            <FilterForm
              filters={didListFilters}
              setFilters={setDidListFilter}
             />
            <ReferenceManyField reference="DidList" target="orgIdEq" label=""
              sort={defaultSort}
              filter={didListFilter}
              perPage={20}
              pagination={<PaginationDefault />}
            >
              {didListDatagrid}
            </ReferenceManyField>
          </div>
        </Tab>
        <Tab label="resources.AttributeList.many" path="AttributeList">
          <div className="nested-resource" >
            <FilterForm
              filters={attributeListFilters}
              setFilters={setAttributeListFilter}
             />
            <ReferenceManyField reference="AttributeList" target="orgIdEq" label=""
              sort={defaultSort}
              filter={attributeListFilter}
              perPage={20}
              pagination={<PaginationDefault />}
            >
              {attributeListDatagrid}
            </ReferenceManyField>
          </div>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
}

const FunctionFieldForDownload = ({id, handleDownloadValues, resource, fields}) => {
  const translate = useTranslate();
  return <FunctionField render={record => <MuiButton
      startIcon={<Download />}
      id={id}
      onClick={() => handleDownloadValues(record.id, resource, fields)}
    >
      {translate(`resources.${resource}.downloadValues`)}
    </MuiButton>
    }
  />
}

const OrgCreate = () => {
  return (
    <Create title='resources.Org.create' resource="Org" redirect="show">
        <SimpleForm warnWhenUnsavedChanges>
          <TextInput source="emailAddress" validate={[required(), email()]}  autoComplete="off"/>
          <TextInput source="fullName" validate={[required()]} autoComplete="off" />
          <SelectLang source="lang" />
          <TextInput source="clientId" validate={[required()]} autoComplete="off" />
          <TextInput source="publicName" validate={[required()]} autoComplete="off" />
          <SelectWallet source="wallet" validate={[required()]} />
          <NestedVidchainFields isCreate={true} />
          <TextInput source="webCallbacksUrl" autoComplete="off" />
          <TextInput source="logoUrl" autoComplete="off" />
          <TextInput source="backgroundColor" autoComplete="off" />
          <TextInput source="foregroundColor" autoComplete="off" />
          <TextInput source="footerHtml" fullWidth multiline minRows={5} autoComplete="off" />
          <SelectOrgs source="orgIdDuplicate" />
          <BooleanInput source="multiwallet" />
        </SimpleForm>
    </Create>
  );
}


const NestedVidchainFields = ({isCreate}) => {
  const { watch } = useFormContext();
  const wallet = watch("wallet");
  
  const validate = (secret = false) =>  {
    return wallet === "VIDWALLET" && !secret && [required()];
  }

  return (<>
    <TextInput source='vidconnectClientId' validate={validate()} autoComplete="off" />
    {isCreate ?
      <TextInput source="vidconnectClientSecret" validate={validate()} autoComplete="off" />
      :
      <FunctionField render={record => {
        return <TextInput source="vidconnectClientSecretUpdate" validate={validate(record.vidconnectClientSecret)} autoComplete="off" />
      }}/>
    }
  </>);
};


const OrgTitle = ({ record }: {record?: any}) => {
  return <span>Org {record ? `"${record.publicName || record.id}"` : ''}</span>;
};

const OrgEdit = () => {
  return (
    <Edit title={<OrgTitle />} redirect="show">
      <SimpleForm toolbar={<OnlySaveToolbar />} warnWhenUnsavedChanges>
        <TextInput readOnly source="id" />
        <TextInput source="clientId" validate={[required()]} autoComplete="off" />
        <TextInput source="publicName" validate={[required()]} autoComplete="off" />
        <SelectWallet source="wallet" validate={[required()]} />
        <NestedVidchainFields isCreate={false} />
        <TextInput source="webCallbacksUrl" autoComplete="off" />
        <TextInput source="logoUrl" autoComplete="off" />
        <TextInput source="foregroundColor" autoComplete="off" />
        <TextInput source="backgroundColor" autoComplete="off" />
        <TextInput source="footerHtml" fullWidth multiline minRows={5} autoComplete="off" />
        <BooleanInput source="multiwallet" />
      </SimpleForm>
    </Edit>
  )
};

export {OrgList, OrgShow, OrgCreate, OrgEdit};
